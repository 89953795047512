<template>
  <footer class="shadow-sm rounded mt-5">
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.00);">
    © 2023 Copyright:
    <a class="text-reset fw-bold" href="/">JK-Food.com</a>
  </div>
  </footer>
</template>

<script>
export default {
    name : 'FooterView'
}
</script>

<style>

</style>