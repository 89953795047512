<template>
  <div class="row mt-5">
    <div class="col">
        <div class="d-flex h-100 ml-5">
            <div class="justify-contet-center align-self-center">
                <h3><strong>Buka Puasa Enak,</strong></h3><h5>pakai JK-Food aja.</h5>
                <p>Ayo segera pilih makanan favorit anda</p>
                <router-link to="/foods" class="btn btn-success"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-handbag-fill" viewBox="0 0 16 16">
  <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z"/>
</svg>  pesan</router-link
          >
            </div>
        </div>
    </div>
    <div class="col">
        <img src="../assets/ilustrasi.png" height="400px">
    </div>
  </div>
</template>

<script>
export default {
    name : 'HeroMenu'
}
</script>

<style>

</style>